@use '@/styles/utils/mixins.scss' as *;
.contactMenuWrapper {
  background-color: $white;
  display: flex;
  padding: 1.875rem;
  margin-top: 1.25rem;
  flex-direction: column;
  gap: 2rem;
  -webkit-box-shadow: 0rem 0.25rem 0.25rem 0rem rgba($neutral03, 0.5);
  -moz-box-shadow: 0rem 0.25rem 0.25rem 0rem rgba($neutral03, 0.5);
  box-shadow: 0rem 0.25rem 0.25rem 0rem rgba($neutral03, 0.5);

  .menuIconBtn {
    padding: 0.375rem;
  }
  .contactTitle {
    width: 100%;
  }
  .contactList {
    display: flex;

    margin: 0;
    padding: 0;
    gap: 2.25rem;
    list-style: none;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: $primary;
    li {
      display: flex;
      align-items: flex-start;
      gap: 0.75rem;
      flex-direction: column;
      width: 50%;
    }
    a {
      font-weight: 600;
      font-size: 1.25rem;
      color: $dark;
      margin-top: auto;
      &:hover,
      &:focus,
      &:active {
        color: $primary;
      }
    }
  }
}
